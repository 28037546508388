.tableModal{
    font-size: 100%;
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border: 1px solid black;
}
.tableModal td,
.tableModal th {
    border: 1px solid black;
    padding: 0.5rem 1rem;
}
.tableModal thead th {
    padding: 2px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    background: #efecec;
}
.tableModal td {
    background: #fff;
    padding: 2px 4px;
    text-align: left;
}

.tableModal tbody th {
    font-weight: 100;
    font-style: italic;
    text-align: left;
    position: relative;
}

.tableModal thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}
.tableModal tbody th {
     position: sticky;
     left: 0;
     background: #efecec;
     z-index: 1;
 }

.tableModal tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background: #efecec;
}

[role="region"][aria-labelledby][tabindex] {
    width: 100%;
    max-height: 98vh;
    overflow: auto;
}
[role="region"][aria-labelledby][tabindex]:focus {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    outline: 0;
}
.modal-90w{
    max-width: none!important;
    width: 90%;
}

